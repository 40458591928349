import { BaseController } from './BaseController'

export interface CanCredentialResponse {
  can: boolean
}

export class KioskController extends BaseController {
  readonly url_render_credential: string = `${this.apiHost}/${this.apiPathKiosk}/render-credential`
  readonly url_can_credential: string = `${this.apiHost}/${this.apiPathKiosk}/can-credential`

  async renderCredentialEmail (): Promise<any> {
    const response = await this.useFetch('POST', 'renderCredential', this.url_render_credential, {
      image: false,
      email: true
    })

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async getCanCredential (): Promise<CanCredentialResponse> {
    console.log(this.url_can_credential)
    const response = await this.useFetch('POST', 'canCredential', this.url_can_credential)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }
}

export default KioskController
