import React, { useState } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import {
  AtlasPage,
  Drawer,
  DrawerLayout,
  DrawerSize,
  DrawerType,
  MobileView,
  NavItemProps,
  ReleaseNoteItemProps,
  ReleaseNotes,
  TemplateMasterDetail,
  TextType,
  Text
} from '@mit/hui'
import { MainNavBar } from 'Components/nav'
import OnboardingMaster from 'Components/onboarding/OnboardingMaster'
import OnboardingDetail from 'Components/onboarding/OnboardingDetail'
import { getEnv } from 'TemplateConstants/common'
import { LoggedInUser } from 'TemplateConstants/components'
import { useStoreActions } from 'Store/hooks'

const App: React.FC = () => {
  const { setSelectedTask } = useStoreActions(actions => actions.taskModel)

  const [display, setDisplay] = useState<MobileView>(MobileView.Master)
  const [showWhatsNew, setShowWhatsNew] = useState<boolean>(false)
  const [utilNavItems] = useState<NavItemProps[]>([
    { icon: 'thumbs-up', text: 'Welcome', iconOnly: false, onClick: () => setSelectedTask(null) },
    { icon: 'bell', text: "What's new!", iconOnly: false, onClick: () => setShowWhatsNew(true) },
    { icon: 'question-circle', text: 'Help', iconOnly: false, properties: { href: 'mailto:info-help@mit.edu' } }
  ])

  const releaseNotes: ReleaseNoteItemProps[] = [
    {
      date: '1/18/2021',
      text: 'First release of Onboarding',
      items: []
    }
  ]

  return (
    <Router>
      <AtlasPage
        gaId='G-FZ5CSBT5ND'
        environment={getEnv()}
        utilityNavigation={utilNavItems}
        theme='max-contrast'
        name='Onboarding'
        contentAriaLabel=' '
        customLogo={
          <svg width='49px' height='54px' viewBox='0 0 49 54' version='1.1'>
            <title>Atlas Logo</title>
            <g id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
              <g id='Primary-Menu' transform='translate(-22.000000, -21.000000)'>
                <g id='Logo' transform='translate(22.000000, 21.000000)'>
                  <path
                    d='M28.3506045,0 L49,54 L32.1588946,54 L29.3238342,46.6401869 L15.275475,46.6401869 L12.3557858,54 L8.50518135,54 L0,40.5 L15.275475,0 L28.3506045,0 Z M22.1727116,23.4672897 L18.5759931,33.3084112 L25.8963731,33.3084112 L22.1727116,23.4672897 Z'
                    id='Combined-Shape'
                    fill='#000000'
                  />
                  <polygon id='Path-4' fill='#DE4141' points='0 40.5 11 40 8.5 54' />
                </g>
              </g>
            </g>
          </svg>
        }
        subtitle='Welcome to MIT'
        navigation={<MainNavBar />}
        profile={<LoggedInUser />}
        content={
          <>
            <Switch>
              <Route exact path='/'>
                <TemplateMasterDetail
                  master={<OnboardingMaster onItemSelect={() => setDisplay(MobileView.Detail)} />}
                  detail={<OnboardingDetail />}
                  detailTitle=''
                  masterTitle=''
                  navigation=''
                  onBack={() => setDisplay(MobileView.Master)}
                  mobileView={display}
                  startState
                />
              </Route>
            </Switch>
          </>
        }
      />
      <Drawer
        contentAriaLabel="What's New Release Notes"
        show={showWhatsNew}
        onClose={() => setShowWhatsNew(false)}
        type={DrawerType.Right}
        size={DrawerSize.Medium}
        layout={DrawerLayout.Hero}
        header={<Text content="What's New!" type={TextType.Heading4} icon='bell' />}
        contents={
          <>
            <ReleaseNotes items={releaseNotes} />
          </>
        }
        footer={[]}
      />
    </Router>
  )
}

export default App
