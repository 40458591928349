import { BaseController } from './BaseController'

export interface File {
  applicationId?: string
  contentType: string
  fileName: string
  fileType?: string
  status?: string
}

export interface Meta {
  enabled: boolean
  completed: boolean
  documentCountLimit: number
  documents: MetaDocument[]
  acceptedDocumentContentTypes: string[]
}

export interface MetaDocument {
  applicationId: string
  contentType: string
  createdAt: string
  createdBy: string
  documentType: { id: string; name: string; canApprove: boolean }
  fileName: string
  fileType: string
  id: string
  s3SignedUrl: string
  status: string
}

export class FileController extends BaseController {
  async getMeta (path: string): Promise<Meta> {
    const response = await this.useFetch('GET', 'fetchMeta', path)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async uploadMeta (taskId: string, path: string, files: File[]): Promise<Meta> {
    const response = await this.useFetch('PUT', 'putMeta', path, {
      onboardingTaskId: taskId,
      documents: files
    })

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async approve (path: string, status: string, statusComment: string | null): Promise<Meta> {
    const body = statusComment
      ? {
          status: status,
          statusComment: statusComment
        }
      : { status: status }
    const response = await this.useFetch('POST', 'postApprove', path, body)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }

  async upload (url: string | any, file: any, fileName?: any): Promise<any> {
    let response = await this.useFetch('PUT', 'put/upload', url, file, {}, true)

    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  async download (url: string): Promise<any> {
    let response = await fetch(url)

    if (this.checkStatusCode(response.status)) {
      return { success: true }
    } else if (response.status === 404) {
      return { success: false }
    } else {
      let text = await response.text()
      return Promise.reject(text)
    }
  }

  // async upload(s3SignedUrl: string, file: File): Promise<any> {
  //   const response = await this.useFetch('PUT', 'upload', s3SignedUrl, this.getBlob(file.fileName))

  //   if (this.checkStatusCode(response.status)) {
  //     const data = await response.json()
  //     return data
  //   } else {
  //     const data = await response.json()
  //     return await Promise.reject(data.message)
  //   }
  // }

  // private getBlob = async (fileUri: string) => {
  //   const resp = await fetch(fileUri)
  //   const imageBody = await resp.blob()
  //   return imageBody
  // }
}

export default FileController
