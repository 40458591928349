// import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { FileUpload, Icon, Spacer } from '@mit/hui'
import { useStoreActions, useStoreState } from 'Store/hooks'

// import { KioskController } from 'Api/controller/KioskController'
import FileController, { Meta, MetaDocument } from 'Api/controller/FileController'
import { Skeleton } from 'Common/skeleton'

const fileController = new FileController()

interface loadingObject {
  id: string
  isLoading: boolean
}

const FileUploadActionComponent: FC<any> = ({ itm, setGlobalMessage }) => {
  const { setTrack } = useStoreActions(actions => actions.taskModel)
  const { selectedTask, track, selectedCategory } = useStoreState(store => store.taskModel)
  const [actionsIsLoading, setActionsIsLoading] = useState<loadingObject[]>([])
  const [action, setAction] = useState<{ meta: { path: string }; name: string; platform: string } | null>(null)
  const [meta, setMeta] = useState<Meta | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [hoverIndex, setHoverIndex] = useState(-1)

  const markTaskAsCompleted = (): void => {
    const updatedCategories = track?.categories.map(category => {
      if (category.name === selectedCategory?.name) {
        const updatedTasks = category.tasks.map(task => {
          if (task.id === selectedTask?.id) {
            return {
              ...task,
              track: {
                answerDate: '',
                taskId: '',
                answer: {
                  action: 'Completed',
                  text: ''
                }
              }
            }
          }
          return task
        })

        if (!selectedTask?.optional && selectedTask?.track.answer?.action !== 'Completed') {
          let updatedCategory = category
          updatedCategory.progress.taskAnswerCount += 1
          updatedCategory.progress.taskCompletePercentage = Math.round(
            (updatedCategory.progress.taskAnswerCount / updatedCategory.progress.taskCount) * 100
          )

          return { ...updatedCategory, tasks: updatedTasks }
        }
        return { ...category, tasks: updatedTasks }
      }
      return category
    })

    if (!selectedTask?.optional && selectedTask?.track.answer?.action !== 'Completed') {
      let updatedProgress = track?.progress!
      updatedProgress.taskAnswerCount += 1
      updatedProgress.taskCompletePercentage = Math.round((updatedProgress.taskAnswerCount / updatedProgress.taskCount) * 100)
      setTrack({ progress: updatedProgress, categories: updatedCategories! })
    } else {
      setTrack({ progress: track?.progress!, categories: updatedCategories! })
    }
  }

  const fetchMeta = async () => {
    try {
      setGlobalMessage({ text: '', type: 'success' })
      const uploadMetaPromise = await fileController.getMeta(itm.meta.path)
      uploadMetaPromise.documents = uploadMetaPromise.documents.map(document => {
        let status = 'No Status'
        switch (document.status) {
          case 'A':
            status = 'Approved'
            break
          case 'P':
            status = 'Pending'
            break
          case 'R':
            status = 'Rejected'
            break
          case 'N':
            status = 'Not Uploaded'
            break
        }
        return { ...document, status: status }
      })
      setMeta(uploadMetaPromise)
      setLoading(false)
      return uploadMetaPromise
    } catch (e) {
      setLoading(false)
      setGlobalMessage({ text: 'Upload currently unavailable', type: 'error' })
    }
    // return uploadMetaPromise
  }

  useEffect(() => {
    if (selectedTask && selectedTask.actions && selectedTask.actions.length > 0) {
      let loadingData = selectedTask!.actions.map(itm => {
        let data: loadingObject = {
          id: itm.name,
          isLoading: false
        }
        return data
      })

      setActionsIsLoading(loadingData)
    }
    setAction(itm)
    if (itm && itm.meta && itm.meta.path) {
      fetchMeta()
      // setLoading(false)
    } else {
      setGlobalMessage({ text: 'Upload currently unavailable', type: 'error' })
    }

    // eslint-disable-next-line
  }, [selectedTask])

  useEffect(() => {
    setLoading(true)
  }, [itm])

  const actionsEvents = (actionName: string, file: any) => {
    switch (actionName.toLowerCase()) {
      case 'visiting-student': {
        let copyActionLoading = [...actionsIsLoading]

        let index = copyActionLoading.findIndex(itm => itm.id === actionName)

        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        const files = [
          {
            contentType: file.type,
            fileName: file.name
          }
        ]

        fileController
          .uploadMeta(selectedTask!.id, action!.meta.path, files)
          .then(meta => {
            if (meta.documents.length > 0) return fileController.upload(meta.documents[0].s3SignedUrl, file)
            else throw Error('Could not perform action at this time')
          })
          .then(() => fetchMeta())
          .then(result => {
            if (result) {
              setMeta({ ...meta!, enabled: result.enabled, completed: result.completed, documents: result.documents })
              setLoading(false)
              if (result.completed) markTaskAsCompleted()
            }

            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: 'Files uploaded successfully', type: 'success' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
          .catch(e => {
            setLoading(false)
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        setLoading(false)
        setGlobalMessage({ text: 'Could not perform action at this time', type: 'error' })
    }
  }

  if (isLoading) return <Skeleton height={'252px'} />

  return (
    <>
      {meta && (meta.documents.length > 0 || meta.enabled)  && (
        <>
          <div className='text-left border rounded p-3 TEST'>
            {meta.documents.length! > 0 && (
              <div>
                <div>Uploaded files</div>
                {meta?.documents.map((document: MetaDocument, index: number) => {
                  return (
                    <div>
                      <a href={document.s3SignedUrl} target='_blank' rel='noreferrer'>
                        <Icon icon='file' type='light' /> {document.fileName}
                      </a>{' '}
                      <small>({document.status})</small>
                    </div>
                  )
                })}

                <Spacer />
              </div>
            )}

            {action && meta?.enabled && (
              <>
                <FileUpload
                  text='Upload'
                  onConfirm={file => {
                    actionsEvents(action.name, file)
                  }}
                  multiple={false}
                  isLoading={actionsIsLoading.find(item => item.id === itm.name)?.isLoading ?? false}
                  acceptedMimeTypes={meta?.acceptedDocumentContentTypes}
                />
                <Spacer />
              </>
            )}
          </div>
          <Spacer />
        </>
      )}
    </>
  )
}

const UploadAction = withRouter(FileUploadActionComponent)
export default UploadAction
