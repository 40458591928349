import React from 'react'

interface SkeletonProps {
  width?: string
  height?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({ width = '100%', height = '45px' }) => {
  return <span className='skeleton-loader' style={{ width, height }}></span>
}
