import { FC } from 'react'
import { withRouter } from 'react-router'
import { HtmlText } from 'Common/HtmlText'
import { Spacer } from '@mit/hui'

const SidePictureLandingComponent: FC<any> = ({ welcomeContent }) => {
  return (
    <div className='OFY-welcome row'>
      <div className='col-lg-8'>
        <Spacer size='3' />
        <HtmlText text={welcomeContent ?? ''} />
      </div>
      <div className='col-lg-4 text-center'>
        {
          // eslint-disable-next-line
          <img alt='MIT Tim the beaver' src='/mit-tim.png' />
        }
      </div>
    </div>
  )
}

const SidePictureLanding = withRouter(SidePictureLandingComponent)
export default SidePictureLanding
