import { Alert, AlertProps, Button, ButtonType, Spacer, Text } from '@mit/hui'
import { PossibleAnswersInterface, TrackController } from 'Api/controller/TrackController'
import { displayDateAbbrMonth } from 'Common/Formatter'
import { HtmlText } from 'Common/HtmlText'
import ProgressBar from 'Common/Progress'
import { Skeleton } from 'Common/skeleton'
import ToggleItems from 'Common/ToggleItem'
import ApiAction from 'Components/actions/ApiAction'
import FileApproveAction from 'Components/actions/FileApproveAction'
import FileDownloadAction from 'Components/actions/FileDownloadAction'
import FileUploadAction from 'Components/actions/FileUploadAction'
import NavigateAction from 'Components/actions/NavigateAction'
import FullLanding from 'Components/landings/full'
import SidePictureLanding from 'Components/landings/sidePictures'
import moment from 'moment'
import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { useStoreActions, useStoreState } from 'Store/hooks'

const trackController = new TrackController()

const OnboardingDetailComponent: FC<any> = () => {
  const { selectedTask, selectedCategory, track } = useStoreState(store => store.taskModel)
  const { welcome } = useStoreState(store => store.globalModel)
  const { setSelectedTask, setTrack, setSelectedCategory } = useStoreActions(actions => actions.taskModel)
  const [taskColor, setTaskColor] = useState('')
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [isLoading, setLoading] = useState<boolean>(false)

  const [message, setMessage] = React.useState<AlertProps>({
    text: '',
    type: 'success'
  })
  const [globalMessage, setGlobalMessage] = React.useState<AlertProps>({
    text: '',
    type: 'success'
  })

  const [isBusy, setIsBusy] = React.useState<boolean>(false)
  const [selectedAnswer, setSelectedAnswer] = React.useState<PossibleAnswersInterface>({ action: '', text: '' })

  useEffect(() => {
    setLoading(false)
    setGlobalMessage({ text: '', type: 'success' })
    if (!selectedTask || !track) {
      return
    }
    // setLoading(true)
    // setTimeout(() => {
    //   setLoading(false)
    // }, 2000)
    const selectedCategory = track.categories.find(c => {
      return c.name === selectedTask.category
    })
    if (!selectedCategory) {
      return
    }
    setTaskColor(selectedCategory.meta.primaryColor)
    // eslint-disable-next-line
  }, [selectedTask])

  const updateAnswer = (answer: PossibleAnswersInterface): void => {
    if (selectedTask?.id) {
      setSelectedAnswer(answer)
      setIsBusy(true)
      trackController.putTrack(selectedTask?.id, answer.text).then(success => {
        if (success) {
          setIsBusy(false)
          setSelectedAnswer({ text: '', action: '' })
          const newAnswerTask = { ...selectedTask }
          newAnswerTask.track.answer = answer
          setSelectedTask(newAnswerTask)

          trackController
            .fetchTrack()
            .then(response => {
              if (response.items != null) {
                setTrack(response.items)

                if (selectedCategory != null) {
                  const catIndex = response.items.categories.findIndex(itm => itm.name === selectedTask.category)
                  const catCopy = response.items.categories[catIndex]
                  setSelectedCategory(catCopy)
                }
              }
            })
            .catch(() => {
              setIsBusy(false)
              setSelectedAnswer({ text: '', action: '' })
            })
            .finally(() => {
              setSelectedAnswer({ text: '', action: '' })
              setIsBusy(false)
            })

          setMessage({ text: 'Successfully updated answer', type: 'success' })

          setTimeout(() => {
            setMessage({ text: '', type: 'success' })
          }, 3000)
        } else {
          setMessage({ text: 'Unsuccessfully updated answer', type: 'error' })

          setTimeout(() => {
            setMessage({ text: '', type: 'success' })
          }, 3000)
        }
      })
    }
  }

  const getIcons = (answer: string): string => {
    if (answer === 'Not Applicable') return 'ban'

    if (answer === 'Not Completed') return 'times'

    return 'check'
  }

  const getToggleData: any = () => {
    return selectedTask?.possibleAnswers.map(itm => {
      return {
        text: itm.text,
        icon: getIcons(itm.action),
        loading: !!(selectedAnswer.text === itm.text && isBusy),
        onClick: () => updateAnswer(itm),
        selected: selectedTask.track.answer == null && itm.text === 'Not Completed' ? true : selectedTask.track.answer?.text === itm.text
      }
    })
  }

  const getDetailContent = (): any => {
    // Welcome message
    if (welcome.template === 'full') return <FullLanding welcomeContent={welcome.content} />
    else if (welcome.template === 'side-pictures') return <SidePictureLanding welcomeContent={welcome.content} />
    else {
      return <></>
    }
  }

  if (isLoading)
    return (
      <div className={`container`}>
        <div className='row'>
          <div className='col-12'>
            <ProgressBar title='Global Progress' percentage={`${track?.progress.taskCompletePercentage.toString() ?? '0'}`} />
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-lg-8 text-left'>
            <Skeleton height={'40px'} width={'60%'} />
            <Skeleton height={'18px'} width={'40%'} />
            <Spacer />
            <Skeleton height={'18px'} width={'25%'} />
            <Spacer />
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.25rem' }}>
              <Skeleton height={'79px'} width={'124px'} />
              <Skeleton height={'79px'} width={'124px'} />
              <Skeleton height={'79px'} width={'124px'} />
            </div>
          </div>
          <div className='col-lg-4 text-center actions'>
            <Skeleton height={'47px'} />
            <Skeleton height={'47px'} />
          </div>
        </div>
      </div>
    )

  console.error('selectedTask', selectedTask)

  return (
    <>
      <div className={`container ${selectedTask?.category}`}>
        <div className='row'>
          <div className='col-12'>
            <ProgressBar title='Global Progress' percentage={`${track?.progress.taskCompletePercentage.toString() ?? '0'}`} />
          </div>
        </div>
        {selectedTask?.id && (
          <>
            {globalMessage.text && <Alert {...globalMessage} />}
            <div className='row text-center'>
              <div className='col-lg-8 text-left'>
                <h3>
                  {selectedTask?.title ?? ''}
                  {selectedTask.optional && <span className='badge rounded-pill bg-info'>optional</span>}
                </h3>
                <Text bold content={selectedTask.subHeading} />
                <Spacer />
                <div className='duedate'>
                  {selectedTask.dueDate && (
                    <Text content={`Due date ${displayDateAbbrMonth(selectedTask.dueDate)}`} color={selectedTask.overdue ? 'red' : undefined} />
                  )}
                </div>
                <Spacer />
                {selectedTask.type === 'Manual' ? (
                  <> {moment().isSameOrAfter(selectedTask.startDate) && <ToggleItems items={getToggleData()} taskColor={taskColor} />}</>
                ) : (
                  <></>
                )}
                {message.text && <Alert {...message} />}
                <Spacer />
                <HtmlText text={selectedTask.description ?? ''} />
              </div>

              <div className='col-lg-4 text-center actions'>
                {selectedTask.actions && selectedTask.actions.length > 0 && (
                  <>
                    {selectedTask.actions
                      .filter(itm => itm.platform.toLowerCase() === 'web')
                      .map(itm => {
                        if (itm.type.toLowerCase() === 'api') {
                          return <ApiAction itm={itm} setGlobalMessage={setGlobalMessage} />
                        } else if (itm.type.toLowerCase() === 'file-upload') {
                          return <FileUploadAction itm={itm} setGlobalMessage={setGlobalMessage} />
                        } else if (itm.type.toLowerCase() === 'file-approve') {
                          return <FileApproveAction itm={itm} setGlobalMessage={setGlobalMessage} />
                        } else if (itm.type.toLowerCase() === 'file-download') {
                          return <FileDownloadAction itm={itm} setGlobalMessage={setGlobalMessage} />
                        } else if (itm.type.toLowerCase() === 'navigate') {
                          return <NavigateAction itm={itm} setGlobalMessage={setGlobalMessage} />
                        } else {
                          return <></>
                        }
                      })}
                  </>
                )}
                {selectedTask.moreInformation && selectedTask.moreInformation.url && (
                  <>
                    <div>
                      <Button
                        id='more-info'
                        text={selectedTask.moreInformation.text}
                        onClick={() => {
                          window.open(selectedTask.moreInformation.url, '_blank')
                        }}
                        type={ButtonType.Primary}
                        onMouseEnter={() => setHoverIndex(0)}
                        onMouseLeave={() => setHoverIndex(-1)}
                        customBackgroundColor={taskColor}
                        customBorderColor={taskColor}
                      />
                    </div>
                    <Spacer />
                  </>
                )}
                {selectedTask.contactEmail && (
                  <div>
                    <Button
                      text='Contact Support'
                      id='contact-support'
                      type={ButtonType.Secondary | ButtonType.Ghost}
                      onClick={() => (window.location.href = `mailto:${selectedTask.contactEmail}`)}
                      onMouseEnter={() => setHoverIndex(1)}
                      onMouseLeave={() => setHoverIndex(-1)}
                      customBackgroundColor={hoverIndex === 1 ? `${taskColor}22` : 'transparent'}
                      customBorderColor={taskColor}
                      customTextColor={taskColor}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!selectedTask?.id && getDetailContent()}
      </div>
    </>
  )
}

const OnboardingDetail = withRouter(OnboardingDetailComponent)
export default OnboardingDetail
