import moment from 'moment'

export const simpleToday = (): string => {
  const displayDate = moment().format('MMM D')
  return `${displayDate}`
}

export const mitFormatToday = (): string => {
  return moment().format('MM/DD/YYYY')
}

export const simpleDate = (d: string): string => {
  return 'May 21'
  // return moment(d, 'MM/DD/YYYY').format('MMM D')
}

export const toDate = (dateTime: string): moment.Moment => {
  return moment(dateTime, 'YYYY-MM-DD H:mm:ss')
}

export const displayDateTime = (date: moment.Moment | string | Date): string => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const displayDate = moment(date).format('LLL')
  return `${displayDate}`
}

export const displayDateAbbrMonth = (date: moment.Moment | string | Date): string => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const displayDate = moment(date).format('ll')
  return `${displayDate}`
}

export const toLocalTimeFromUct = (utcTime: string): string => {
  var date = moment.utc(utcTime).format()
  const local: moment.Moment = moment.utc(date).local()
  return local.format()
}

export const toUtc = (localTime: string): string => {
  return moment.utc(moment(localTime)).format()
}

export const toUtcDate = (localTime: moment.Moment): string => {
  return moment.utc(localTime).format()
}

export const formatPhoneNumber = (number: string): string => {
  const cleaned = ('' + number).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match != null) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return number
}

export const addHours = (inDate: string, hours: number): moment.Moment => {
  const date: moment.Moment = toDate(inDate).add(hours, 'hours')
  return date
}

export const displayDate = (date: moment.Moment | string): string => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const displayDate = moment(date).format('MM/DD/YYYY')
  return `${displayDate}`
}

export const getResultText = (item: any): string => {
  switch (item.result) {
    case 'P':
      return 'Positive'
    case 'N':
      return 'Negative'
    case 'I':
      return 'Invalid'
    case 'FLU_SHOT':
      return 'Flu Shot'
    case 'VACCINE_SHOT':
      return item.test_company
    case 'VACCINE_COURSE_COMPLETED':
      return item.test_company
  }

  return 'Pending'
}
