import { FC } from 'react'
import { withRouter } from 'react-router'
import { HtmlText } from 'Common/HtmlText'
import { Spacer } from '@mit/hui'

const FullLandingComponent: FC<any> = ({ welcomeContent }) => {
  return (
    <div className='OFY-welcome row'>
      <div className='col'>
        <Spacer size='3' />
        <HtmlText text={welcomeContent ?? ''} />
      </div>
    </div>
  )
}

const FullLanding = withRouter(FullLandingComponent)
export default FullLanding
