import * as React from 'react'
import { FC, useState } from 'react'
import {
  ActionList,
  Error,
  ErrorContext,
  ErrorType,
  ListItemState,
  TextType,
  Text,
  TemplateModal,
  Icon,
  Button,
  ButtonType,
  TemplateModalAlignment,
  TemplateModalSize
} from '@mit/hui'
import TaskItem from 'Common/TaskItem'
import { useStoreActions, useStoreState } from 'Store/hooks'
import { TrackController, TrackInterface } from 'Api/controller/TrackController'
import { displayDateAbbrMonth } from 'Common/Formatter'
import ProgressBar from 'Common/Progress'
import moment from 'moment'

interface Props {
  onItemSelect: () => void
}

const trackController = new TrackController()

const OnboardingMasterComponent: FC<Props> = ({ onItemSelect }) => {
  const { setSelectedTask, setTrack, setSelectedCategory } = useStoreActions(actions => actions.taskModel)
  const { selectedTask, track } = useStoreState(store => store.taskModel)
  const [showDoneModal, setShowDone] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)

  const fetchTrack = (): void => {
    setIsLoading(true)

    trackController
      .fetchTrack()
      .then(response => {
        if (response.items != null) {
          setTrack(response.items)

          if (response.items.progress.taskCompletePercentage === 100) {
            setShowDone(true)
          }

          setIsLoading(false)
        } else {
          setIsLoading(false)
        }
      })
      .catch(() => setIsLoading(false))
      .finally(() => setIsLoading(false))
  }

  // const updateTask = (categoryName: string, taskId: string, task: any): void => {
  //   console.log(categoryName, taskId, task)
  //   // const updatedCategories = track?.categories.map((category) => {
  //   //   if (category.name === categoryName) {
  //   //     const updatedTasks = category.tasks
  //   //     return { ...category, tasks: updatedTasks }
  //   //   }
  //   //   return category
  //   // })
  //   // setTrack({ progress: track?.progress!, categories: updatedCategories! })
  // }

  React.useEffect(() => {
    fetchTrack()

    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (selectedTask != null && track != null) {
      const trackCopy = { ...track }
      const categoriesCopy = [...trackCopy?.categories]
      const categoryIndex = categoriesCopy.findIndex(x => x.name === selectedTask.category)

      const tasksCopy = [...categoriesCopy[categoryIndex].tasks]
      const taskIndex = tasksCopy.findIndex(x => x.id === selectedTask.id)

      if (tasksCopy[taskIndex].track.answer !== selectedTask.track.answer) {
        tasksCopy[taskIndex].track.answer = selectedTask.track.answer
        categoriesCopy[categoryIndex].tasks = tasksCopy
        trackCopy.categories = categoriesCopy
        setTrack(trackCopy)
      }
    }
    // eslint-disable-next-line
  }, [selectedTask?.track.answer])

  const completedCount = (): number => {
    let count = 0
    track?.categories.map(itm => {
      count = count + itm.progress.taskAnswerCount
      return itm
    })
    return count
  }

  const getIcon = (itm: TrackInterface) => {
    if (itm.track.answer == null || itm.track.answer.action === 'Not Completed' || itm.track.answer.action === 'Not Answered') return 'circle'
    else return 'check-circle'
  }

  const checkCompleted = (task: TrackInterface) => {
    if (task.track.answer == null || task.track.answer.action === 'Not Completed' || task.track.answer.action === 'Not Answered') return true
    else return false
  }

  return (
    <>
      <div className='text-right'>
        {showCompleted && (
          <>
            {
              // eslint-disable-next-line
              <a className='toggleHidden' onClick={() => setShowCompleted(false)}>
                <i className='fas fa-eye-slash' /> Hide completed <span>{completedCount()}</span>
              </a>
            }
          </>
        )}
        {!showCompleted && (
          <>
            {
              // eslint-disable-next-line
              <a className='toggleHidden' onClick={() => setShowCompleted(true)}>
                <i className='fas fa-eye' /> Show completed <span>{completedCount()}</span>
              </a>
            }
          </>
        )}
      </div>
      <div className='container onboarding-colors'>
        {isLoading && <ActionList isLoading items={[]} />}
        {!isLoading && (
          <>
            {track != null && track?.categories.length > 0 ? (
              track.categories.map(key => {
                if (
                  key.tasks.filter(itm =>
                    showCompleted
                      ? true
                      : itm.track.answer == null || itm.track.answer.action === 'Not Completed' || itm.track.answer.action === 'Not Answered'
                  ).length > 0
                ) {
                  return (
                    <>
                      <div role='list' className={key.name}>
                        <div className='row'>
                          <div className='col-6'>
                            <h3 style={{ color: key.meta.primaryColor }}>{key.name}</h3>
                          </div>
                          <div className='col-6 listed-progress'>
                            <ProgressBar
                              percentage={`${key.progress?.taskCompletePercentage.toString() ?? '0'}`}
                              color={key.meta.primaryColor}
                              hideText
                            />
                          </div>
                        </div>

                        {key.tasks
                          .filter(itm =>
                            showCompleted
                              ? true
                              : itm.track.answer == null || itm.track.answer.action === 'Not Completed' || itm.track.answer.action === 'Not Answered'
                          )
                          .map((task: TrackInterface, index: number) => {
                            return (
                              <TaskItem
                                // updateTask={updateTask}
                                color={key.meta.primaryColor}
                                key={index}
                                task={task}
                                optional={task.optional}
                                onClick={() => {
                                  setSelectedTask(task)
                                  setSelectedCategory(key)
                                  onItemSelect()
                                }}
                                icon={getIcon(task)}
                                notApplicable={task.track.answer?.text === 'Not Applicable'}
                                complete={checkCompleted(task)}
                                overdue={task.overdue}
                                text={task.title}
                                tertiaryText={
                                  moment().isBefore(task.startDate)
                                    ? `Start date ${displayDateAbbrMonth(task.startDate)}`
                                    : task.dueDate
                                    ? `Due date ${displayDateAbbrMonth(task.dueDate)}`
                                    : ''
                                }
                                state={selectedTask?.id === task.id ? ListItemState.Active : ListItemState.None}
                              />
                            )
                          })}
                      </div>
                    </>
                  )
                } else {
                  return <></>
                }
              })
            ) : (
              <Error context={ErrorContext.Component} message='No tasks available' type={ErrorType.NoData} />
            )}
          </>
        )}
      </div>
      <TemplateModal
        body={
          <>
            <div className='container p-5 text-center'>
              <div>
                <Icon icon='check' type='solid' color='green' />
              </div>
              <div>
                <Text content='You are 100% done' type={TextType.Heading3} />
              </div>
              <div>
                <Button text='Review my statuses' type={ButtonType.Ghost | ButtonType.Secondary} onClick={() => setShowDone(false)} />
              </div>
            </div>
          </>
        }
        bodyAlignment={TemplateModalAlignment.Center}
        footer={[]}
        header={<></>}
        containerless
        autoHeight
        size={TemplateModalSize.Default}
        name='done'
        show={showDoneModal}
        onClose={() => {
          setShowDone(false)
        }}
      />
    </>
  )
}

const OnboardingMaster = OnboardingMasterComponent
export default OnboardingMaster
