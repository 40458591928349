import React from 'react'
import { SkeletonLoader } from '@mit/hui'

interface Props {
  text: string
  isLoading?: boolean
}

export const HtmlText: React.FC<Props> = ({ text, isLoading }) => {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(text)
  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <React.Fragment>
          {isHTML ? <div className='html-text-detailed' dangerouslySetInnerHTML={{ __html: text }}></div> : <p className='html-text'>{text}</p>}
        </React.Fragment>
      )}
    </>
  )
}
