import { String } from 'aws-sdk/clients/cloudwatchevents'
import { BaseController } from './BaseController'

export interface WelcomeResponse {
  content?: string
  template?: string
  error?: boolean
}
export interface TrackResponse {
  items?: TrackObject
  error?: boolean
}

export interface FeatureResponse {
  items?: FeatureInterface[]
  error?: boolean
}

export interface DownloadResponse {
  s3SignedUrl?: string
  error?: boolean
}
export interface TrackObject {
  categories: CategoriesInterface[]
  progress: ProgressInterface
}

export interface ProgressInterface {
  taskAnswerCount: number
  taskCompletePercentage: number
  taskCount: number
}

export interface MetaInterface {
  icon: string
  primaryColor: string
  secondaryColor: string
}

export interface CategoriesInterface {
  meta: MetaInterface
  name: string
  order: number
  progress: ProgressInterface
  tasks: TrackInterface[]
}

export interface ActionInterface {
  name: string
  platform: string
  type: String
}

export interface TrackInterface {
  actions?: ActionInterface[]
  category: string
  completeText: string
  contactEmail: string
  contactNumber: string
  description: string
  dueDate: string
  id: string
  moreInformation: MoreInformationInterface
  optional: boolean
  overdue: boolean
  possibleAnswers: PossibleAnswersInterface[]
  startDate: string
  subHeading: string
  targetAudiences: string[]
  taskDepenencyId: string
  title: string
  type: 'Auto' | 'Manual'
  track: TrackInfoInterface
}

export interface MoreInformationInterface {
  text: string
  url: string
}

export interface PossibleAnswersInterface {
  text: string
  action: string
}

export interface TrackInfoInterface {
  taskId: string
  answer?: PossibleAnswersInterface | null
  answerDate: string
}

export interface FeatureInterface {
  feature: string
  enabled: boolean
}

export interface WelcomeInterface {
  content: string
  template: string
}

export class TrackController extends BaseController {
  readonly url_welcome: string = `${this.apiHost}/${this.apiPath}/welcome`
  readonly url_track: string = `${this.apiHost}/${this.apiPath}/track`
  readonly url_feature: string = `${this.apiHost}/${this.apiPath}/features`
  readonly url_downloads: string = `${this.apiHost}/${this.apiPath}/downloads`

  // constructor() {
  //   super()

  //   this.url_track = `${this.apiHost}/${this.apiPath}/track`
  //   this.url_feature = `${this.apiHost}/${this.apiPath}/features`
  // }

  async fetchWelcome(): Promise<WelcomeResponse> {
    const response = await this.useFetch('GET', 'fetchWelcome', this.url_welcome)

    if (this.checkStatusCode(response.status)) {
      const data = (await response.json()) as WelcomeResponse
      return data
    } else {
      return {
        error: true
      }
    }
  }

  async fetchTrack(): Promise<TrackResponse> {
    const response = await this.useFetch('GET', 'fetchTrack', this.url_track)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      return {
        error: true
      }
    }
  }

  async fetchFeature(): Promise<FeatureResponse> {
    const response = await this.useFetch('GET', 'fetchFeature', this.url_feature)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return {
        items: data
      }
    } else {
      return {
        error: true
      }
    }
  }

  async fetchDownload(uuid: string): Promise<DownloadResponse> {
    const response = await this.useFetch('GET', 'fetchDownload', `${this.url_downloads}/${uuid}/url`)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      return {
        error: true
      }
    }
  }

  async putTrack(taskId: string, answer: string): Promise<boolean> {
    const response = await this.useFetch('PUT', 'putTrack', `${this.url_track}/${taskId}`, { answer: answer })

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      return false
    }
  }

  async deleteTrack(taskId: string): Promise<any> {
    const response = await this.useFetch('Delete', 'putTrack', `${this.url_track}/${taskId}`)

    if (this.checkStatusCode(response.status)) {
      return true
    } else {
      return false
    }
  }
}

let trackController = new TrackController()
export default trackController
