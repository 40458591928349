// import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Button, ButtonState, ButtonType, DropDownState, FormField, Icon, Spacer, Textbox } from '@mit/hui'
import { useStoreActions, useStoreState } from 'Store/hooks'

// import { KioskController } from 'Api/controller/KioskController'
import FileController, { Meta, MetaDocument } from 'Api/controller/FileController'
import { Skeleton } from 'Common/skeleton'

const fileController = new FileController()

interface loadingObject {
  id: string
  isLoading: boolean
}

const FileApproveActionComponent: FC<any> = ({ itm, setGlobalMessage }) => {
  const { setTrack } = useStoreActions(actions => actions.taskModel)
  const { selectedTask, track, selectedCategory } = useStoreState(store => store.taskModel)
  const [actionsIsLoading, setActionsIsLoading] = useState<loadingObject[]>([])
  const [action, setAction] = useState<
    | {
        meta: { path: string; options?: { label: string; value: string; reasonRequired: boolean }[] }
        name: string
        platform: string
      }
    | null
  >(null)
  const [meta, setMeta] = useState<Meta | null>(null)
  const [rejectReason, setRejectReason] = useState<string>('')
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [responseSelection, setResponseSelection] = useState<{ label: string; value: string; reasonRequired: boolean } | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [hoverIndex, setHoverIndex] = useState(-1)

  const markTaskAsCompleted = (): void => {
    const updatedCategories = track?.categories.map(category => {
      if (category.name === selectedCategory?.name) {
        const updatedTasks = category.tasks.map(task => {
          if (task.id === selectedTask?.id) {
            return {
              ...task,
              track: {
                answerDate: '',
                taskId: '',
                answer: {
                  action: 'Completed',
                  text: ''
                }
              }
            }
          }
          return task
        })

        if (!selectedTask?.optional && selectedTask?.track.answer?.action !== 'Completed') {
          let updatedCategory = category
          updatedCategory.progress.taskAnswerCount += 1
          updatedCategory.progress.taskCompletePercentage = Math.round(
            (updatedCategory.progress.taskAnswerCount / updatedCategory.progress.taskCount) * 100
          )
          return { ...updatedCategory, tasks: updatedTasks }
        }

        return { ...category, tasks: updatedTasks }
      }
      return category
    })

    if (!selectedTask?.optional && selectedTask?.track.answer?.action !== 'Completed') {
      let updatedProgress = track?.progress!
      updatedProgress.taskAnswerCount += 1
      updatedProgress.taskCompletePercentage = Math.round((updatedProgress.taskAnswerCount / updatedProgress.taskCount) * 100)
      setTrack({ progress: updatedProgress, categories: updatedCategories! })
    } else {
      setTrack({ progress: track?.progress!, categories: updatedCategories! })
    }
  }

  useEffect(() => {
    const fetchMeta = async () => {
      try {
        setGlobalMessage({ text: '', type: 'success' })
        const uploadMetaPromise = await fileController.getMeta(itm.meta.path)
        return uploadMetaPromise
      } catch (e) {
        console.log(e)
        setGlobalMessage({ text: 'Upload currently unavailable', type: 'error' })
      }
      // return uploadMetaPromise
    }
    if (selectedTask && selectedTask.actions && selectedTask.actions.length > 0) {
      let loadingData = selectedTask!.actions.map(itm => {
        let data: loadingObject = {
          id: itm.name,
          isLoading: false
        }
        return data
      })
      setActionsIsLoading(loadingData)
    }

    setAction(itm)
    if (itm && itm.meta && itm.meta.path) {
      fetchMeta().then(result => {
        setMeta(result!)
        setLoading(false)
      })
    } else {
      setLoading(false)
      setGlobalMessage({ text: 'Upload currently unavailable', type: 'error' })
    }
    // eslint-disable-next-line
  }, [selectedTask])

  useEffect(() => {
    setLoading(true)
  }, [itm])

  const respnseChange = (value: string) => {
    const option = action ? action.meta.options?.find(item => item.value === value) : null

    if (option) {
      setResponseSelection(option)
    } else {
      setResponseSelection(null)
    }
  }

  const actionsEvents = (actionName: string) => {
    switch (actionName.toLowerCase()) {
      case 'visiting-student': {
        if (!responseSelection) {
          setGlobalMessage({ text: 'Could not perform action at this time', type: 'error' })
          return
        }
        let copyActionLoading = [...actionsIsLoading]
        let index = copyActionLoading.findIndex(itm => itm.id === actionName)
        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        const statusComment = responseSelection?.reasonRequired ? rejectReason : null

        fileController
          .approve(action!.meta.path, responseSelection.value, statusComment)
          .then(result => {
            setMeta({ ...meta!, enabled: result.enabled, completed: result.completed })
            setLoading(false)
            if (result.completed) markTaskAsCompleted()
            return result
          })
          .then(result => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: `Files approval submitted: ${responseSelection.label}`, type: 'success' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
          .catch(e => {
            setLoading(false)
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        setLoading(false)
        setGlobalMessage({ text: 'Could not perform action at this time', type: 'error' })
    }
  }

  if (isLoading) return <Skeleton height={'181px'} />

  return (
    <>
      {meta && (
        <>
          <div className='text-left border rounded p-3'>
            {meta?.documents.length! > 0 && (
              <div>
                <div>Uploaded files</div>
                {meta?.documents.map((document: MetaDocument, index: number) => {
                  return (
                    <div>
                      <a href={document.s3SignedUrl} target='_blank' rel='noreferrer'>
                        <Icon icon='file' type='light' /> {document.fileName}
                      </a>
                    </div>
                  )
                })}
              </div>
            )}
            {action && meta?.enabled && (
              <>
                <FormField
                  editor='dropdown'
                  label='Reponse'
                  labelDisplay='ontop'
                  id='action'
                  options={action.meta.options?.map(option => {
                    return {
                      id: option.value,
                      icon: '',
                      text: option.label,
                      state: DropDownState.None
                    }
                  })}
                  onChange={(e: any) => {
                    respnseChange(e.id)
                  }}
                />
                {responseSelection?.reasonRequired && (
                  <>
                    <Textbox
                      name='reject-reason'
                      placeholderText='Reject reason'
                      onChange={(e: any) => {
                        setRejectReason(e.currentTarget.value)
                      }}
                    />
                    <Spacer />
                    <Spacer />
                  </>
                )}
                <Button
                  state={responseSelection ? ButtonState.Enabled : ButtonState.Disabled}
                  id='file-response'
                  text='Submit'
                  onClick={() => {
                    actionsEvents(action.name)
                  }}
                  type={ButtonType.Secondary | ButtonType.Ghost}
                  onMouseEnter={() => setHoverIndex(0)}
                  onMouseLeave={() => setHoverIndex(-1)}
                  customBackgroundColor={hoverIndex === 0 ? `${selectedCategory!.meta.primaryColor}22` : 'transparent'}
                  customBorderColor={selectedCategory!.meta.primaryColor}
                  customTextColor={selectedCategory!.meta.primaryColor}
                />
              </>
            )}
          </div>
          <Spacer />
        </>
      )}
    </>
  )
}

const ApproveAction = withRouter(FileApproveActionComponent)
export default ApproveAction
