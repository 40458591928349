import React, { useEffect } from 'react'
import { useStoreActions } from 'Store'
import { TrackController } from 'Api/controller'
import validationsController from 'Api/controller/ValidationsController'

export const AppInitializer: React.FC = () => {
  const { setHasAccess, setIsLoading } = useStoreActions(action => action.LoaderModel)
  const { setFeature, setWelcome } = useStoreActions(action => action.globalModel)

  useEffect(() => {
    /* Do app initialization in here.
       Authentication Api, Lookup Api.... Anything that needs to be loaded before the main app
    */
    // Simulate api call. Don't use timeout
    // setTimeout(() => {
    //   setHasAccess(false)
    //   setIsLoading(false)
    // }, 3000)

    const trackContoller = new TrackController()

    let featurePromise = trackContoller.fetchFeature()
    let welcomePromise = trackContoller.fetchWelcome()
    let sessionPromise = validationsController.fetchHasSession()

    Promise.allSettled([featurePromise, welcomePromise, sessionPromise])
      .then(resp => {
        let hasAccess: boolean = false
        //featurePromise
        if (resp[0].status === 'fulfilled') {
          if (resp[0].value.items) {
            setFeature(resp[0].value.items)
          }
        }

        //welcomePromise
        if (resp[1].status === 'fulfilled') {
          if (resp[1].value.content) {
            setWelcome({
              content: resp[1].value.content,
              template: resp[1].value.template!
            })
          }
        }

        //sessionPromise
        if (resp[2].status === 'fulfilled') {
          if (resp[2].value.items?.result) hasAccess = true
        }

        setHasAccess(hasAccess)
        setIsLoading(false)
      })
      .catch(() => {
        setHasAccess(false)
        setIsLoading(false)
      })

    // eslint-disable-next-line
  }, [])

  return <></>
}
