import { ListItemState } from '@mit/hui'
import { TrackController, TrackInterface } from 'Api/controller/TrackController'
import * as React from 'react'
import { FC, useState } from 'react'
import { useStoreActions } from 'Store/hooks'

interface Props {
  // updateTask: any
  task: TrackInterface
  onClick?: () => void
  text: string
  icon: string
  state?: ListItemState
  secondaryText?: string
  tertiaryText?: string
  quaternaryText?: string
  color?: string
  loading?: boolean
  complete: boolean
  overdue?: boolean
  notApplicable?: boolean
  isBusy?: boolean
  optional?: boolean
}

const trackController = new TrackController()

const TaskItemComponent: FC<Props> = props => {
  const { setTrack } = useStoreActions(actions => actions.taskModel)
  const [taskColor, setTaskColor] = useState<string>('transparent')

  const [isBusy, setIsBusy] = React.useState(false)

  const getClasses = (): string => {
    var classes: string[] = []

    if (props.state === ListItemState.Active) classes.push('active')
    else if (props.state === ListItemState.Disabled) classes.push('disabled')

    if (props.state === ListItemState.RecentlyAdded) classes.push('recently-added')

    if (props.icon !== '') classes.push('hasIcon')

    if (props.notApplicable === true) classes.push('not-applicable')

    if (props.loading !== null && props.loading === true) classes.push('loading')

    return classes.join(' ')
  }

  const updateAnswer = (id: string, answer: string): void => {
    if (id !== '') {
      setIsBusy(true)
      trackController
        .putTrack(id, answer)
        .then(success => {
          if (success) {
            trackController
              .fetchTrack()
              .then(response => {
                if (response.items != null) {
                  setTrack(response.items)
                  setIsBusy(false)
                }
              })
              .catch(error => setIsBusy(false))
          }
        })
        .catch(error => setIsBusy(false))
    }
  }

  return (
    <div role='listitem' className='list-item'>
      {
        // eslint-disable-next-line
        <a
          className={`${props.complete ? ' ' : 'completed'} list-group-item flex-column align-items-start ${getClasses()}`}
          tabIndex={0}
          href='#'
          onClick={props.onClick}
          onMouseEnter={() => setTaskColor(`${props.color || '#ffffff'}22`)}
          onMouseLeave={() => setTaskColor('transparent')}
          onFocus={() => setTaskColor(`${props.color || 'transparent'}`)}
          onBlur={() => setTaskColor('transparent')}
          // style={{ background: props.state === ListItemState.Active ? props.color || 'transparent' : `${taskColor}` }}
          ref={node => {
            if (node) {
              props.state === ListItemState.Active
                ? node.style.setProperty('background', `${props.color || 'transparent'}`, 'important')
                : node.style.setProperty('background', `${taskColor}`, 'important')
            }
          }}
        >
          <div className={`${!props.overdue ? 'overdue-hidden' : 'overdue'}`}>
            <span>
              <i className='fas fa-exclamation-circle' />
            </span>
          </div>

          <div className='d-flex w-100 justify-content-between'>
            <p className='primary'>
              {isBusy ? (
                <span className='loadericon'>
                  <i className={`rotate-center fas fa-spinner-third`} />
                </span>
              ) : (
                <i
                  className={`${props.complete ? 'fal' : 'fas'} fa-${props.icon}`}
                  onClick={() => updateAnswer(props.task.id, props.task.track.answer?.action !== 'Completed' ? 'Completed' : 'Not Completed')}
                />
              )}
              {props.text !== undefined && <span className={`${props.complete ? 'bold' : ' '}`} dangerouslySetInnerHTML={{ __html: props.text }} />}
            </p>
            {
              // props.optional && (
              // <div className='badge-container'>
              //  <span className='badge rounded-pill bg-info'>optional</span>
              // </div>
              // )
            }
            {props.secondaryText !== null && (
              <div className='d-flex'>
                <p className='text-right'>{props.secondaryText}</p>
              </div>
            )}
          </div>
          <div className='d-flex w-100 justify-content-between'>
            {/* ensure the padding is not present if icon is not here */}
            <p className='additional'>{props.tertiaryText}</p>
            <p className='additional text-right'>{props.quaternaryText}</p>
          </div>
        </a>
      }
    </div>
  )
}

const TaskItem = TaskItemComponent
export default TaskItem
