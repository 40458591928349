import { BaseController as BaseApiController } from 'TemplateConstants/controller'

// Override any methods from TemplateConstant BaseController here. Extend all other controllers off this controller
export class BaseController extends BaseApiController {
  readonly apiPath: any
  readonly apiPathKiosk: string
  readonly apiPathVisitingStudent: string

  constructor () {
    super()
    this.apiPath = this.appConfig.api.paths.onboarding
    this.apiPathKiosk = this.appConfig.api.paths.kiosk
    this.apiPathVisitingStudent = this.appConfig.api.paths.visitingStudent
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }
}
