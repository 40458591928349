import { BaseController } from './BaseController'

export interface NavigationResponse {
  navigationUrl: string
  enabled: boolean
}

export class NavigateController extends BaseController {
  async getNavigationUrl(url: string): Promise<NavigationResponse> {
    const response = await this.useFetch('GET', 'renderCredential', url)

    if (this.checkStatusCode(response.status)) {
      const data = await response.json()
      return data
    } else {
      const data = await response.json()
      return await Promise.reject(data.message)
    }
  }
}

export default NavigateController
