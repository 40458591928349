// import * as React from 'react'
import { Button, ButtonType, Spacer, Text } from '@mit/hui'
import KioskController from 'Api/controller/KioskController'
import NavigateController from 'Api/controller/NavigateController'
import { Skeleton } from 'Common/skeleton'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { useStoreState } from 'Store/hooks'

interface loadingObject {
  id: string
  isLoading: boolean
}

interface ActionItem {
  name: string
  type: string
  platform: string
  meta: {
    path: string
    label: string
  }
}

const NavigateActionComponent: FC<any> = ({ itm, setGlobalMessage }) => {
  const { selectedTask, selectedCategory } = useStoreState(store => store.taskModel)
  const [taskColor, setTaskColor] = useState('')
  const [action, setAction] = useState<{ meta: { path: string; label: string }; name: string; platform: string } | null>(null)

  useEffect(() => {
    if (!selectedCategory) {
      return
    }

    setTaskColor(selectedCategory.meta.primaryColor)
    setAction(itm)
  }, [selectedTask, selectedCategory, itm])

  const props = {
    taskColor,
    action,
    itm,
    setGlobalMessage
  }

  if (itm.name.toLowerCase() === 'open-mitid-print-qr') {
    return <NavigateActionQrCodeComponent {...props} />
  }
  if (itm.name.toLowerCase().includes('get-web-link')) {
    return <NavigateActionWebLinkComponent {...props} />
  }

  return <></>
}

const NavigateAction = withRouter(NavigateActionComponent)
export default NavigateAction

const NavigateActionQrCodeComponent: FC<any> = ({ itm, setGlobalMessage, taskColor, action }) => {
  const kioskController = new KioskController()

  const { selectedTask } = useStoreState(store => store.taskModel)
  const [actionsIsLoading, setActionsIsLoading] = useState<loadingObject[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let _action = itm as ActionItem

    switch (_action.name.toLowerCase()) {
      case 'open-mitid-print-qr': {
        setLoading(true)

        let copyActionLoading = [...actionsIsLoading]
        let index = copyActionLoading.findIndex(itm => itm.id === _action.name.toLowerCase())

        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        kioskController
          .getCanCredential()
          .then(result => {
            setShow(result.can)
            setLoading(false)
          })
          .catch(e => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)
            setLoading(false)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        setShow(true)
    }
    // eslint-disable-next-line
  }, [selectedTask])

  if (isLoading) return <Skeleton height={'45px'} />

  return (
    <>
      {show && (
        <div>
          <Button
            id='more-info'
            text={action?.meta.label}
            onClick={() => {
              window.open(action?.meta.path, '_blank')
            }}
            type={ButtonType.Primary}
            // onMouseEnter={() => setHoverIndex(0)}
            // onMouseLeave={() => setHoverIndex(-1)}
            customBackgroundColor={taskColor}
            customBorderColor={taskColor}
          />
          <Spacer />
        </div>
      )}
    </>
  )
}

const NavigateActionWebLinkComponent: FC<any> = ({ itm, setGlobalMessage, taskColor, action }) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  const navigationController = new NavigateController()

  const onPressNavigateButton = async (): Promise<void> => {
    const _action = itm as ActionItem
    let webLink: string | null = _action.meta.path

    if (_action.name.toLowerCase() === 'get-web-link-on-press') {
      webLink = null
      setLoading(true)

      try {
        const navigateResponse: { navigationUrl: string } = await navigationController.getNavigationUrl(_action.meta.path)
        webLink = navigateResponse?.navigationUrl ?? null
      } catch (e) {
        setGlobalMessage({ text: e, type: 'error' })
      } finally {
        setLoading(false)
      }
    }

    if (webLink != null && webLink !== '') {
      window.open(webLink ?? '', '_blank')
    }
  }

  return (
    <>
      <div>
        <Button
          id='more-info'
          text={action?.meta.label}
          onClick={onPressNavigateButton}
          isBusy={isLoading}
          type={ButtonType.Primary}
          // onMouseEnter={() => setHoverIndex(0)}
          // onMouseLeave={() => setHoverIndex(-1)}
          customBackgroundColor={taskColor}
          customBorderColor={taskColor}
          properties={{}}
        />
        <Text
          content={'Pressing the button will open a link in a new tab. You may need to disable your pop-up blocker.'}
          type={'small'}
          italic
          // color={'red'}
        />
        <Spacer />
      </div>
    </>
  )
}
