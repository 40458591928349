import * as React from 'react'
import { FC, useState } from 'react'

export interface ToggleItem {
  selected: boolean
  text: string
  icon: string
  onClick: () => void
  id: string
  loading: boolean
}

interface Props {
  items: ToggleItem[]
  taskColor: string
}

const ToggleItems: FC<Props> = props => {
  const [hover, setHover] = useState(-1)

  const getBackgroundColor = (index: number, selected: boolean) => {
    if (selected) {
      return props.taskColor
    } else if (index === hover) {
      return `${props.taskColor}22`
    } else {
      return 'transparent'
    }
  }

  return (
    <div className='option-toggles'>
      {props.items.map((itm, index) => {
        return (
          <>
            {
              // eslint-disable-next-line
              <a
                className={`${itm.selected ? 'selected' : ''} ${itm.loading ? 'loading' : ''}`}
                onClick={itm.onClick}
                style={{ background: getBackgroundColor(index, itm.selected), color: itm.selected ? 'white' : `${props.taskColor}88` }}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(-1)}
              >
                {!itm.loading ? (
                  <i className={`fas fa-${itm.icon}`} />
                ) : (
                  <div className='busy-indicator'>
                    <div className='loader'>
                      <div className='pulsate-loading' />
                    </div>
                  </div>
                )}
                {itm.text}
              </a>
            }
          </>
        )
      })}
    </div>
  )
}

export default ToggleItems
