import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import 'Scss/main.scss'
import { Initialization } from 'TemplateConstants/components'
import { Logout } from 'TemplateConstants/components/Logout'

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <StoreProvider store={store}>
        <Switch>
          <Route exact path='/logout' component={Logout} />
          <Initialization
            loaderProps={{
              contactEmail: '',
              name: 'Onboarding',
              exit: false,
              noAccessContent: 'No Onboarding needed at this time'
            }}
          />
        </Switch>
      </StoreProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
)
