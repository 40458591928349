import * as React from 'react'
import { FC } from 'react'

interface Props {
  percentage: string
  title?: string
  color?: string
  hideText?: boolean
}

const ProgressComponent: FC<Props> = props => {
  if (Number(props.percentage) < 100) {
    return (
      <div className='progressbar-container'>
        {props.title !== null && <h6>{props.title}</h6>}
        <div className='progressbar'>
          <div className='progress' style={{ width: props.percentage + '%', background: props.color || '' }} />
          {!props.hideText && (
            <div style={{ left: props.percentage + '%' }} className='progressNumber'>
              {props.percentage}
              <span>%</span>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className='progressbar-container text-right'>
        {props.title && <h6>{props.title}</h6>}
        <i className='far fa-check' /> Completed
      </div>
    )
  }
}

const ProgressBar = ProgressComponent
export default ProgressBar
