import React from 'react'

import { NavBar, NavBarTypes, NavItemProps, NavItemState } from '@mit/hui'

export const MainNavBar: React.FC = () => {
  const tabs: NavItemProps[] = [
    {
      icon: 'sun',
      iconOnly: false,
      text: 'Onboarding',
      to: '/',
      state: window.location.pathname === '/' ? NavItemState.Active : NavItemState.None
    }
  ]

  return <NavBar type={NavBarTypes.IconText} tabs={tabs} />
}
