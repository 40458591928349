// import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Button, ButtonType, Spacer } from '@mit/hui'
import { useStoreState } from 'Store/hooks'
import { Skeleton } from 'Common/skeleton'
import { TrackController } from 'Api/controller/TrackController'

interface loadingObject {
  id: string
  isLoading: boolean
}

const trackController = new TrackController()

const FileDownloadActionComponent: FC<any> = ({ itm, setGlobalMessage }) => {
  const { selectedTask, selectedCategory } = useStoreState(store => store.taskModel)
  const [taskColor, setTaskColor] = useState('')
  const [actionsIsLoading, setActionsIsLoading] = useState<loadingObject[]>([])
  const [downloadPath, setDownloadPath] = useState<string | null>(null)
  // const [hoverIndex, setHoverIndex] = useState(-1)
  const [action, setAction] = useState<{ meta: { path?: string; label: string; uuid?: string }; name: string; platform: string } | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [hoverIndex, setHoverIndex] = useState(-1)

  useEffect(() => {
    if (!selectedCategory) {
      return
    }

    setTaskColor(selectedCategory.meta.primaryColor)
    setAction(itm)
  }, [selectedTask, selectedCategory, itm])

  useEffect(() => {
    let _action = itm as { meta: { path?: string; label: string; uuid?: string }; name: string; platform: string }

    switch (_action.name.toLowerCase()) {
      case 'onboarding-downloads': {
        setLoading(true)

        let copyActionLoading = [...actionsIsLoading]
        let index = copyActionLoading.findIndex(itm => itm.id === _action.name.toLowerCase())

        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        trackController
          .fetchDownload(_action.meta.uuid ? _action.meta.uuid : 'none')
          .then(result => {
            if (result.s3SignedUrl) setDownloadPath(result.s3SignedUrl)
            setLoading(false)
          })
          .catch(e => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)
            setLoading(false)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        break
    }
    // eslint-disable-next-line
  }, [selectedTask])

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  // }, [itm])

  if (isLoading) return <Skeleton height={'45px'} />

  return (
    <>
      {downloadPath && (
        <div>
          <Button
            id='more-info'
            text={action?.meta.label}
            onClick={() => {
              window.open(downloadPath, '_blank')
            }}
            type={ButtonType.Primary}
            // onMouseEnter={() => setHoverIndex(0)}
            // onMouseLeave={() => setHoverIndex(-1)}
            customBackgroundColor={taskColor}
            customBorderColor={taskColor}
          />
          <Spacer />
        </div>
      )}
    </>
  )
}

const FileDownloadAction = withRouter(FileDownloadActionComponent)
export default FileDownloadAction
