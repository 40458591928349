// import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Button, ButtonState, ButtonType, Spacer } from '@mit/hui'
import { useStoreState } from 'Store/hooks'

import { KioskController } from 'Api/controller/KioskController'
import { Skeleton } from 'Common/skeleton'

const kioskController = new KioskController()

interface loadingObject {
  id: string
  isLoading: boolean
}

const ApiActionComponent: FC<any> = ({ itm, setGlobalMessage }) => {
  const { selectedTask, selectedCategory } = useStoreState(store => store.taskModel)
  const [taskColor, setTaskColor] = useState('')
  const [canCredential, setCanCredential] = useState<boolean>(false)
  const [actionsIsLoading, setActionsIsLoading] = useState<loadingObject[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  // const [hoverIndex, setHoverIndex] = useState(-1)

  useEffect(() => {
    if (!selectedCategory) {
      return
    }
    setTaskColor(selectedCategory.meta.primaryColor)

    if (selectedTask && selectedTask.actions && selectedTask.actions.length > 0) {
      let loadingData = selectedTask!.actions.map(itm => {
        let data: loadingObject = {
          id: itm.name,
          isLoading: false
        }

        return data
      })

      setActionsIsLoading(loadingData)
    }
  }, [selectedTask, selectedCategory])

  useEffect(() => {
    let _action = itm as { meta: { path: string; label: string }; name: string; platform: string }

    switch (_action.name.toLowerCase()) {
      case 'email-mitid-print-qr': {
        setLoading(true)

        let copyActionLoading = [...actionsIsLoading]
        let index = copyActionLoading.findIndex(itm => itm.id === _action.name.toLowerCase())

        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        kioskController
          .getCanCredential()
          .then(result => {
            setCanCredential(result.can)
            setLoading(false)
          })
          .catch(e => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)
            setLoading(false)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        setGlobalMessage({ text: 'Could not perform action at this time', type: 'error' })
    }
    // eslint-disable-next-line
  }, [selectedTask])

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000);
  // }, [itm])

  const actionsEvents = (actionName: string) => {
    switch (actionName.toLowerCase()) {
      case 'email-mitid-print-qr': {
        let copyActionLoading = [...actionsIsLoading]

        let index = copyActionLoading.findIndex(itm => itm.id === actionName)

        if (index >= 0) {
          copyActionLoading[index].isLoading = true
          setActionsIsLoading(copyActionLoading)
        }

        kioskController
          .renderCredentialEmail()
          .then(() => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: 'Email send successfully', type: 'success' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
          .catch(e => {
            copyActionLoading[index].isLoading = false
            setActionsIsLoading(copyActionLoading)

            setGlobalMessage({ text: e, type: 'error' })

            setTimeout(() => {
              setGlobalMessage({ text: '', type: 'success' })
            }, 3000)
          })
        break
      }
      default:
        setGlobalMessage({ text: 'Could not perform action at this time', type: 'error' })
    }
  }

  if (isLoading) return <Skeleton height={'45px'} />
  return (
    <>
      {canCredential && (
        <div>
          <Button
            id='action'
            text={itm.name} // @TODO use name to get text from text-api
            onClick={() => {
              actionsEvents(itm.name)
            }}
            isBusy={actionsIsLoading.find(item => item.id === itm.name)?.isLoading ?? false}
            state={actionsIsLoading.find(item => item.id === itm.name)?.isLoading ? ButtonState.Disabled : ButtonState.Enabled}
            type={ButtonType.Primary}
            // onMouseEnter={() => setHoverIndex(0)}
            // onMouseLeave={() => setHoverIndex(-1)}
            customBackgroundColor={taskColor}
            customBorderColor={taskColor}
          />
          <Spacer />
        </div>
      )}
    </>
  )
}

const ApiAction = withRouter(ApiActionComponent)
export default ApiAction
